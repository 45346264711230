import request from '@/api/request.js'

/**
 * @Description: 商品品牌
 */

// 分页查询
export function pageWmsSkuBrand(data) {
    return request({
        url: '/api/wmsSkuBrand/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsSkuBrand(data) {
    return request({
        url: '/api/wmsSkuBrand/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsSkuBrand(data) {
    return request({
        url: '/api/wmsSkuBrand/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsSkuBrand(data) {
    return request({
        url: '/api/wmsSkuBrand/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsSkuBrand(data) {
    return request({
        url: '/api/wmsSkuBrand/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsSkuBrand(data) {
    return request({
        url: '/api/wmsSkuBrand/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsSkuBrand(data) {
    return request({
        url: '/api/wmsSkuBrand/batchDelete',
        method: 'POST',
        data
    })
}

